.generic-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
}

.generic-popup .backdrop {
  background: rgb(0 0 0 / 75%);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.generic-popup__content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  color: #2b3e5b;
  margin-bottom: 30px;
}

.generic-popup__content__header button {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.generic-popup__content {
  background: #fff;
  max-width: 350px;
  text-align: center;
  border-radius: 4px;
  padding: 20px;
}

.generic-popup__content p {
  margin-bottom: 80px;
  line-height: 22px;
  color: #757070;
}

.generic-popup__content__controls {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 20px;
}

.generic-popup__content__controls button {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  cursor: pointer;
}

.generic-popup__content__controls button:first-child {
  color: #2b3e5b;
  background: #f5f4f3;
}

.generic-popup__content__controls button:last-child {
  background: #2b3e5b;
  color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen {
  .print-only {
    display: none;
  }
}

@page {
  margin: 20mm;
}

@media print {
  .no-print {
    display: none;
    font-size: 12px !important;
  }

  .print-case {
    margin: 24px;
  }

  .MuiTypography-h4 {
    font-size: 12px !important;
  }
  .MuiTypography-h5 {
    font-size: 10px !important;
  }
  .MuiTypography-subtitle2 {
    font-size: 10px !important;
  }
  .MuiTypography-body1 {
    font-size: 10px !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiPaper-rounded {
    border: none !important;
  }
  #handling-warning {
    display: none;
  }
  ._hj_feedback_container {
    display: none;
  }
}
